import axios from 'axios';

const ajaxEmail = (email, firstName, lastName, onEmailAjaxCallResponse) => {

    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': true
    };
    axios.post(
        process.env.REACT_APP_BACKEND ? `${process.env.REACT_APP_BACKEND}/validateEmail` : 'http://localhost:8080/validateEmail',
        {
            "Email": email,
            "FirstName": firstName,
            "LastName": lastName
        },
        { headers }).then(response => {
            onEmailAjaxCallResponse(response, null);
            
        }).catch(error => {
            console.log("Error ========>", error);
            onEmailAjaxCallResponse(null, error);
        })

}

export default ajaxEmail;
